import React from 'react'
import styled from 'styled-components'

const RadioInput = styled.input.attrs({ type: 'radio' })(() => ({}))

const StyledLabel = styled.label(() => ({
  alignItems: 'center',
  display: 'flex',
  fontSize: '1.5rem',
  fontWeight: 300,
}))

interface StyledLabelTextProps {
  $disabled: boolean
}
const StyledLabelText = styled.span<StyledLabelTextProps>(
  ({ theme, $disabled }) => ({
    marginTop: theme.space(0.5),
    marginLeft: theme.space(2),
    color: $disabled ? 'grey' : 'black',
  })
)

export interface RadioButtonProps {
  label: string
  id: string
  value: string
  group: string
  disabled?: boolean
  checked?: boolean
  onChangeValue: () => void
}
const RadioButton: React.FC<RadioButtonProps> = ({
  label,
  id,
  value,
  group,
  disabled,
  checked,
  onChangeValue,
}) => {
  return (
    <>
      <StyledLabel>
        <RadioInput
          disabled={disabled}
          id={id}
          value={value}
          name={group}
          defaultChecked={checked}
          onChange={onChangeValue}
        />
        <StyledLabelText $disabled={!!disabled}>{label}</StyledLabelText>
      </StyledLabel>
    </>
  )
}

export default RadioButton
