import React, { useState } from 'react'
import styled from 'styled-components'

import RadioButton from './RadioButton'

export enum RadioButtonGroupVariants {
  HORIZONTAL = 'horizontal',
  DEFAULT = 'default',
}

const StyledList = styled.ul<{ variant?: RadioButtonGroupVariants }>(
  ({ theme, variant }) => ({
    ...(variant === RadioButtonGroupVariants.HORIZONTAL && {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      gap: theme.space(4),
    }),
    paddingLeft: 0,
  })
)

const StyledListItem = styled.li(({ theme }) => ({
  listStyleType: 'none',
  marginTop: theme.space(0.5),
  marginBottom: theme.space(0.5),
}))

export interface RadioButtonOption {
  id: string
  label: string
  value: string
  disabled: boolean
}

export interface RadioButtonGroupProps {
  buttonsOptions: RadioButtonOption[]
  group: string
  initialValue: string
  variant?: RadioButtonGroupVariants
  getValue?: (value: string) => void
}

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
  buttonsOptions,
  group,
  initialValue,
  variant,
  getValue,
}) => {
  const [currentSelectedValue, setCurrentSelectedValue] = useState(initialValue)

  return (
    <StyledList variant={variant}>
      {buttonsOptions.map(({ label, id, value, disabled }) => (
        <StyledListItem key={id}>
          <RadioButton
            label={label}
            id={id}
            value={value}
            group={group}
            onChangeValue={() => {
              getValue?.(value)
              setCurrentSelectedValue(value)
            }}
            checked={value === currentSelectedValue}
            disabled={disabled}
          />
        </StyledListItem>
      ))}
    </StyledList>
  )
}

export default RadioButtonGroup
