import { ErrorMessage } from '@hookform/error-message'
import React from 'react'
import styled from 'styled-components'

import {
  sharedInputTextAreaStyles,
  StyledHelpLabel,
  StyledInputContainer,
  StyledLabel,
  StyledValidationErrorText,
} from './commonStyles'

interface StyledTextAreaProps {
  error?: boolean
  disabled?: boolean
  height?: number
}

const StyledTextArea = styled.textarea<StyledTextAreaProps>(
  ({ error, theme, disabled, height }) => ({
    ...sharedInputTextAreaStyles({ error, theme, disabled }),
    minHeight: height ? theme.space(height) : theme.space(20),
    padding: theme.space(3),
    resize: 'vertical',

    '&:not([disabled]):focus': {
      borderColor: error ? theme.colors.accent_2 : theme.colors.primary_1,
    },
  })
)

export interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string | JSX.Element
  placeholder?: string
  helpLabel?: string | JSX.Element
  errors?: Record<string, unknown>
  focusOnLoad?: boolean
  verticallySpaced?: boolean
  verticallySpace?: number
  containerStyle?: React.CSSProperties
  height?: number
  name: string
  rows?: number
}

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      label,
      placeholder,
      helpLabel,
      focusOnLoad,
      errors,
      verticallySpaced = true,
      verticallySpace = 8,
      containerStyle,
      height,
      rows,
      ...props
    },
    ref
  ) => {
    return (
      <StyledInputContainer
        verticallySpaced={verticallySpaced}
        verticallySpace={verticallySpace}
        style={containerStyle}
      >
        {label && <StyledLabel>{label}</StyledLabel>}
        {helpLabel && <StyledHelpLabel>{helpLabel}</StyledHelpLabel>}
        <StyledTextArea
          ref={ref}
          autoFocus={focusOnLoad}
          placeholder={placeholder}
          error={!!(errors && errors[props.name])}
          height={height}
          rows={rows}
          {...props}
        />
        {errors && (
          <ErrorMessage
            errors={errors}
            name={props.name}
            render={({ message }) => (
              <StyledValidationErrorText>{message}</StyledValidationErrorText>
            )}
          />
        )}
      </StyledInputContainer>
    )
  }
)

export default TextArea
