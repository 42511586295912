import { Elements, RecurlyProvider, useRecurly } from '@recurly/react-recurly'
import { toast } from 'react-toastify'

import PaymentInfoForm, { PaymentMethodType } from './PaymentInfoForm'
import { useCreatePaymentInformation } from 'src/api/hooks/mutations/useCreatePaymentInformation'
import { useUpdatePaymentInformation } from 'src/api/hooks/mutations/useUpdatePaymentInformation'
import logger from 'src/utils/logger'

const RECURLY_PUBLIC_KEY = process.env.REACT_APP_RECURLY_PUBLIC_KEY!
const CHECK_PAYMENT_INFO_MESSAGE =
  'Please check your payment information and try again.'

const PaymentInformationForm: React.FC<{
  closeCurrentModal: () => void
  locationId: number
  isUpdate?: boolean
  paymentMethodId?: string
}> = ({ closeCurrentModal, locationId, isUpdate, paymentMethodId }) => {
  const recurlyHook = useRecurly()
  const {
    mutateAsync: updatePaymentInformation,
    isPending: isUpdatingPaymentInfo,
  } = useUpdatePaymentInformation(locationId)
  const {
    mutateAsync: createPaymentInformation,
    isPending: isCreatingPaymentInfo,
  } = useCreatePaymentInformation(locationId)

  const getBankAccountToken = async (form: HTMLFormElement) => {
    return new Promise<string>((resolve, reject) => {
      recurly.configure(RECURLY_PUBLIC_KEY)
      recurly.bankAccount.token(form, (err, token) => {
        if (err) {
          return reject(err)
        }

        resolve(token.id)
      })
    })
  }

  const getCreditCardToken = async (form: HTMLFormElement) => {
    return new Promise<string>((resolve, reject) => {
      recurlyHook.token(form, (err, token) => {
        if (err) {
          return reject(err)
        }

        resolve(token.id)
      })
    })
  }

  const onSubmit = async (form: HTMLFormElement, type: PaymentMethodType) => {
    const isBankAccount = type === PaymentMethodType.BANK_ACCOUNT

    try {
      const token = isBankAccount
        ? await getBankAccountToken(form)
        : await getCreditCardToken(form)

      if (isUpdate) {
        await updatePaymentInformation({
          token,
          paymentMethodId,
        })
      } else {
        await createPaymentInformation({
          token,
        })
      }

      closeCurrentModal()
    } catch (err) {
      logger.error('Error updating payment information', {
        locationId,
        err,
      })
      toast.error(CHECK_PAYMENT_INFO_MESSAGE)
    }
  }

  return (
    <PaymentInfoForm
      loading={isUpdatingPaymentInfo || isCreatingPaymentInfo}
      closeCurrentModal={closeCurrentModal}
      onSubmit={onSubmit}
    />
  )
}

const PaymentInfoModal: React.FC<{
  closeCurrentModal: () => void
  locationId: number
  isUpdate?: boolean
  paymentMethodId?: string
}> = ({ closeCurrentModal, locationId, isUpdate, paymentMethodId }) => {
  return (
    <RecurlyProvider publicKey={RECURLY_PUBLIC_KEY}>
      <Elements>
        <PaymentInformationForm
          closeCurrentModal={closeCurrentModal}
          locationId={locationId}
          paymentMethodId={paymentMethodId}
          isUpdate={isUpdate}
        />
      </Elements>
    </RecurlyProvider>
  )
}

export default PaymentInfoModal
