import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'
import * as yup from 'yup'

import { useCreateContact } from 'src/api'
import Input from 'src/stories/Input'
import PhoneNumberInput from 'src/stories/PhoneNumberInput'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'

const Form = styled.form(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gap: theme.space(6),
  marginBottom: theme.space(6),
}))

const StyledColumnsContainer = styled.div<{ columns: number }>(
  ({ theme, columns }) => ({
    display: 'grid',
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
    columnGap: theme.space(3),
    rowGap: theme.space(6),
  })
)

const formSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  emailAddress: yup.string().email('Please enter a valid email'),
  phoneNumber: yup.string().phoneNumber(),
})

type FormSchema = yup.InferType<typeof formSchema>

interface Props {
  onComplete: (contactId?: number, contactPhoneNumber?: string) => void
  dataCy?: string
  locationId: number
}

const AddContactModal: React.FC<Props> = ({
  onComplete,
  dataCy,
  locationId,
}) => {
  const { isMediumScreen: isDesktop } = useScreenSizes()
  const { mutateAsync: createContact } = useCreateContact()

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setError,
  } = useForm<FormSchema>({
    resolver: yupResolver(formSchema),
  })

  const onSubmit = handleSubmit(
    async ({ firstName, lastName, emailAddress, phoneNumber }) => {
      if (!phoneNumber && !emailAddress) {
        const err = {
          message: 'Email address or phone number is required',
          type: 'required',
        }

        setError('emailAddress', err)
        setError('phoneNumber', err)

        return
      }

      const contacts = []

      if (phoneNumber) contacts.push({ phoneNumber })
      if (emailAddress) contacts.push({ emailAddress })

      const contactOrId = await createContact({
        locationId,
        firstName,
        lastName,
        emailAddress,
        phoneNumber,
      })

      onComplete(
        typeof contactOrId === 'number'
          ? contactOrId
          : contactOrId
          ? contactOrId.id
          : undefined,
        phoneNumber
      )
    }
  )

  const baseInputProps = {
    errors,
    verticallySpaced: false,
  }

  return (
    <Form id={dataCy} onSubmit={onSubmit}>
      <Input
        label="First Name:"
        {...baseInputProps}
        {...register('firstName')}
      />
      <Input label="Last Name:" {...baseInputProps} {...register('lastName')} />
      <StyledColumnsContainer columns={isDesktop ? 2 : 1}>
        <Input
          label="Email:"
          type="email"
          {...baseInputProps}
          {...register('emailAddress')}
        />
        <Controller
          control={control}
          name="phoneNumber"
          render={({ field }) => (
            <PhoneNumberInput
              label="Phone number:"
              {...baseInputProps}
              {...field}
            />
          )}
        />
      </StyledColumnsContainer>
    </Form>
  )
}

export default AddContactModal
