import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

interface PatchConversationEventMutationParams {
  conversationId: number
  conversationEventId: number
  message: string
}

let conversationId: number | undefined = undefined

export const usePatchConversationEvent = (locationId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: PatchConversationEventMutationParams) => {
      conversationId = params.conversationId

      return getClient().then((client) =>
        client.patch<void>(
          `/locations/${locationId}/conversations/${params.conversationId}/events/${params.conversationEventId}`,
          {
            message: params.message,
          }
        )
      )
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['conversation-events', { locationId, conversationId }],
      })
    },
  })
}
