import styled, { useTheme } from 'styled-components'

import FeedbackModal from './FeedbackModal'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import { FeedbackConversationEventIcon } from 'src/stories/assets'

interface HasFeedbackProp {
  hasFeedback: boolean
}

const FeedbackSection = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: theme.space(2),
}))

const Link = styled.a<HasFeedbackProp>(({ theme, hasFeedback }) => ({
  fontWeight: 400,
  color: hasFeedback ? theme.colors.base_50 : theme.colors.primary_2,
  marginLeft: theme.space(2),
  textDecorationLine: 'underline',
  cursor: hasFeedback ? 'not-allowed' : 'pointer',
  pointerEvents: hasFeedback ? 'none' : 'auto',
  userSelect: hasFeedback ? 'none' : 'auto',
}))

interface FeedbackProps {
  eventId: number
  disabled: boolean
}

const Feedback: React.FC<FeedbackProps> = ({ eventId, disabled }) => {
  const { showModal } = useModalNotificationsContext()
  const theme = useTheme()

  const handleOpenFeedbackModal = () => {
    if (disabled) {
      return
    }

    showModal({
      title: 'Leave Feedback',
      dataCy: 'feedback-modal',
      hideActionButtons: true,
      customBody: <FeedbackModal eventId={eventId} dataCy="feedback-modal" />,
    })
  }

  return (
    <FeedbackSection>
      <FeedbackConversationEventIcon
        fill={disabled ? theme.colors.base_50 : theme.colors.primary_2}
        strokeWidth={0.5}
      />
      <Link onClick={handleOpenFeedbackModal} hasFeedback={disabled}>
        How did we handle this inquiry? Leave feedback
      </Link>
    </FeedbackSection>
  )
}

export default Feedback
