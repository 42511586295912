import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import ConversationList, {
  SelectableConversationsList,
} from './ConversationList'
import ConversationListHeader from './ConversationListHeader'
import { useBulkUpdateConversation } from 'src/api'
import {
  reduceEditableConversations,
  showNotificationOnMhActions,
} from 'src/components/MessagingHub/utils'
import { HandleTabConversationChangeFn } from 'src/containers/MessagingHub/types'
import { useLocationContext } from 'src/contexts/LocationContext'
import useMhContext from 'src/contexts/MhContext'
import { isEditableConversationItem } from 'src/contexts/MhContext/utils'
import { Col } from 'src/stories/Layout'

export const StyledConversationsBox = styled(Col)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  minWidth: theme.space(84),
}))

interface ConversationsPaneProps {
  conversationsTab: string
  handleTabConversationChange: HandleTabConversationChangeFn
  unreadConversationsCount: number
}

const ConversationsPane: React.FC<ConversationsPaneProps> = ({
  conversationsTab,
  handleTabConversationChange,
  unreadConversationsCount,
}) => {
  const { locationId } = useLocationContext()
  const { isDesktop, openConversationId, sidebarValidColWidth, conversations } =
    useMhContext()
  const [editMode, setEditMode] = useState(false)
  const [selectAll, setSelectAll] = useState(false)
  const [selectableConversationsList, setSelectableConversationsList] =
    useState<SelectableConversationsList[]>([])

  const { mutateAsync: onBulkUpdateConversation } =
    useBulkUpdateConversation(locationId)

  const toggleEditMode = () => {
    setEditMode((curr) => {
      if (curr) {
        setSelectAll(!curr)
        toggleConversation(
          selectableConversationsList.map((s) => s.id),
          !curr
        )
      }

      return !curr
    })
  }

  const toggleConversation = (conversationIds: number[], checked: boolean) => {
    setSelectableConversationsList((list) => {
      return list.map((item) => {
        const shouldToggle = conversationIds.some(
          (id) => id === item.id && isEditableConversationItem(item)
        )

        if (shouldToggle) {
          item.selected = checked
        }

        return item
      })
    })
  }

  useEffect(() => {
    setSelectableConversationsList((old) => {
      return conversations.reduce<SelectableConversationsList[]>((a, c) => {
        if (
          (conversationsTab === 'all' && !c.isArchived) ||
          (conversationsTab === 'unread' && !c.mostRecentEvent.isRead) ||
          (conversationsTab === 'archived' && c.isArchived) ||
          (conversationsTab === 'spam' && c.isSpam) ||
          (conversationsTab.includes('status') &&
            c.statusId?.toString() === conversationsTab.split('-')[1])
        ) {
          a.push({
            ...c,
            selected: old.find((o) => o.id === c.id)?.selected ?? false,
          })
        }

        return a
      }, [])
    })
  }, [conversations, conversationsTab])

  return (
    <StyledConversationsBox
      unspaced
      w={sidebarValidColWidth}
      data-cy="conversations-pane"
    >
      <ConversationListHeader
        conversationsTab={conversationsTab}
        handleTabConversationChange={handleTabConversationChange}
        unreadConversationsCount={unreadConversationsCount}
        editMode={editMode}
        toggleEditMode={toggleEditMode}
        selectAll={selectAll}
        toggleSelectAll={(checked: boolean) => {
          setSelectAll(checked)
          toggleConversation(
            selectableConversationsList.map((s) => s.id),
            checked
          )
        }}
        selectedCount={
          selectAll
            ? selectableConversationsList.length
            : selectableConversationsList.reduce(
                (a, c) => a + (c.selected ? 1 : 0),
                0
              )
        }
        onArchiveClicked={() => {
          void onBulkUpdateConversation({
            locationId,
            conversationId: reduceEditableConversations(
              selectableConversationsList
            ).conversationIds,
            isArchived: conversationsTab !== 'archived',
          }).then((result) => {
            showNotificationOnMhActions(
              'archived',
              result.conversations,
              result.conversationsUpdated
            )
            toggleEditMode()
          })
        }}
        onMarkAsSpamClicked={() => {
          void onBulkUpdateConversation({
            locationId,
            conversationId: reduceEditableConversations(
              selectableConversationsList
            ).conversationIds,
            isSpam: conversationsTab !== 'spam',
          }).then((result) => {
            showNotificationOnMhActions(
              conversationsTab !== 'spam'
                ? 'marked as spam'
                : 'marked as not spam',
              result.conversations,
              result.conversationsUpdated
            )
            toggleEditMode()
          })
        }}
        onMarkReadClicked={() => {
          void onBulkUpdateConversation({
            locationId,
            conversationId: reduceEditableConversations(
              selectableConversationsList
            ).conversationIds,
            conversationEventId: reduceEditableConversations(
              selectableConversationsList
            ).conversationEventIds,
            isRead: true,
          }).then((result) => {
            showNotificationOnMhActions(
              'marked as read',
              result.conversationsEvents,
              result.conversationsEventsUpdated
            )
            toggleEditMode()
          })
        }}
      />
      {/* Conversations List */}
      {(isDesktop || (!isDesktop && !openConversationId)) && (
        <ConversationList
          handleTabConversationChange={handleTabConversationChange}
          editMode={editMode}
          selectableConversationsList={selectableConversationsList}
          toggleConversation={(conversationId: number, checked: boolean) => {
            toggleConversation([conversationId], checked)
          }}
        />
      )}
    </StyledConversationsBox>
  )
}

export default ConversationsPane
