import { ErrorMessage } from '@hookform/error-message'
import {
  CardCvvElement,
  IndividualElementChangeEvent,
} from '@recurly/react-recurly'
import styled from 'styled-components'

import { StyledValidationErrorText } from 'src/stories/commonStyles'

const StyledDiv = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(2),
}))

const CreditCardNumber: React.FC<{
  name: string
  errors?: Record<string, unknown>
  onChange: (value: string) => void
}> = ({ name, errors, onChange }) => {
  const handleChangeEvent = (event: IndividualElementChangeEvent) => {
    if (event.valid) {
      onChange('xxxx')

      return
    }

    if (event.empty) {
      onChange('')

      return
    }

    onChange('x')
  }

  return (
    <StyledDiv>
      <CardCvvElement onChange={handleChangeEvent} />
      {errors && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <StyledValidationErrorText>{message}</StyledValidationErrorText>
          )}
        />
      )}
    </StyledDiv>
  )
}

export default CreditCardNumber
