import { HTMLInputTypeAttribute } from 'react'
import styled, { DefaultTheme } from 'styled-components'

interface StyledInputContainerProps {
  verticallySpaced: boolean
  verticallySpace: number
  type?: HTMLInputTypeAttribute
}

export const StyledInputContainer = styled.div<StyledInputContainerProps>(
  ({ theme, type, verticallySpace, verticallySpaced }) => ({
    display: 'flex',
    flexDirection: type === 'checkbox' ? 'row-reverse' : 'column',
    alignItems: type === 'checkbox' ? 'center' : '',
    justifyContent: 'start',
    margin: `0 0 ${verticallySpaced ? theme.space(verticallySpace) : '0'} 0`,
    gap: type === 'checkbox' ? theme.space(3) : theme.space(1),
    position: 'relative',
  })
)

interface SharedInputTextAreaProps {
  error?: boolean
  theme: DefaultTheme
  disabled?: boolean
}

export const sharedInputTextAreaStyles = ({
  error,
  theme,
  disabled,
}: SharedInputTextAreaProps) => ({
  width: '100%',
  backgroundColor: disabled ? theme.colors.base_5 : theme.colors.base_0,
  color: error ? theme.colors.accent_2 : theme.colors.base_100,
  border: `1px solid ${error ? theme.colors.accent_2 : theme.colors.base_20}`,
  borderRadius: theme.constants.borderRadius,
  fontSize: '1.5rem',

  '&:not([disabled]):hover': {
    borderColor: error ? theme.colors.accent_2 : theme.colors.base_50,
  },

  '&:not([disabled]):focus': {
    color: theme.colors.base_100,
    outline: 'none',
    border: `2px solid ${
      error ? theme.colors.accent_2 : theme.colors.primary_1
    }`,
  },
})

export const StyledLabel = styled.label<{ $type?: HTMLInputTypeAttribute }>(
  ({ $type }) => ({
    fontSize: '1.4rem',
    fontWeight: $type === 'checkbox' ? undefined : 'bold',
    display: 'flex',
  })
)

export const StyledHelpLabel = styled.label(({ theme }) => ({
  fontSize: '1.4rem',
  color: theme.colors.base_40,
  fontWight: '400',
}))

export const StyledValidationErrorText = styled.label(({ theme }) => ({
  marginTop: theme.space(1),
  color: theme.colors.accent_2,
  fontSize: '1.3rem',
}))
