import { useQuery } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { AiSummary } from 'src/api/types'

export interface GetAiInsightsSummary {
  locationId: number
  startDate: Date
  endDate: Date
  enabled: boolean
}

export const useGetAiInsightsSummary = ({
  locationId,
  startDate,
  endDate,
  enabled = true,
}: GetAiInsightsSummary) => {
  const { getClient } = useApiClient()

  return useQuery({
    queryKey: [
      'ai-insights-summary',
      {
        locationId,
        startDate,
        endDate,
      },
    ] as const,
    queryFn: async ({ queryKey: [, params] }) => {
      return getClient()
        .then((client) =>
          client.get<AiSummary>(
            `/locations/${params.locationId}/insights/ai-summary`,
            {
              params: {
                startDate: params.startDate.toISOString(),
                endDate: params.endDate.toISOString(),
              },
            }
          )
        )
        .then((r) => r.data)
    },
    enabled,
  })
}
