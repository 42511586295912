import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import * as yup from 'yup'

import { useCreateFeedback } from 'src/api/hooks/mutations/useCreateFeedback'
import useConversationsListContext from 'src/contexts/ConversationsListContext'
import { useLocationContext } from 'src/contexts/LocationContext'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import { Button } from 'src/stories/Button'
import TextArea from 'src/stories/TextArea'
import { Body, Heading } from 'src/stories/typography'
import logger from 'src/utils/logger'

const StyledContainer = styled.div(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(6),
}))

const Form = styled.form(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gap: theme.space(6),
}))

const StyledButtonsContainer = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.space(6),
}))

const formSchema = yup.object({
  feedback: yup.string().required('Feedback is required'),
})

type FormSchema = yup.InferType<typeof formSchema>

interface FeedbackModalProps {
  eventId: number
  dataCy: string
}

const FeedbackModal: React.FC<FeedbackModalProps> = ({ eventId, dataCy }) => {
  const { closeModal } = useModalNotificationsContext()
  const { locationId } = useLocationContext()
  const { openConversationId } = useConversationsListContext()
  const createFeedback = useCreateFeedback(locationId)

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    register,
  } = useForm<FormSchema>({
    resolver: yupResolver(formSchema),
  })

  const onSubmit = handleSubmit(async ({ feedback }) => {
    try {
      if (!openConversationId) {
        throw new Error('No open conversation ID found')
      }

      await createFeedback.mutateAsync({
        conversationId: openConversationId,
        eventId,
        feedback,
      })
      toast.success('Feedback submitted')
      closeModal()
    } catch (error) {
      logger.error('Failed to submit feedback', { error })
      toast.error('Failed to submit feedback')
    }
  })

  const baseInputProps = {
    errors,
    verticallySpaced: false,
  }

  return (
    <StyledContainer>
      <Heading size="extraLarge" as={'h2'}>
        Submit your Feedback
      </Heading>
      <Body>
        This feedback will be sent directly to the Signpost team to improve your
        answering service experience
      </Body>
      <Form id={dataCy} onSubmit={onSubmit}>
        <TextArea
          label="Suggestions for improvement?"
          placeholder="Please share your thoughts..."
          rows={6}
          {...register('feedback')}
          {...baseInputProps}
        />
        <StyledButtonsContainer>
          <Button
            action="secondary"
            type="button"
            label="Cancel"
            disabled={isSubmitting}
            onClick={closeModal}
          />

          <Button
            action="primary"
            type="submit"
            label="Submit Feedback"
            disabled={isSubmitting}
          />
        </StyledButtonsContainer>
      </Form>
    </StyledContainer>
  )
}

export default FeedbackModal
