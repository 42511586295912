import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

export interface UpdatePaymentInformationParams {
  token: string
}

export const useCreatePaymentInformation = (locationId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ token }: UpdatePaymentInformationParams) => {
      return getClient()
        .then((client) =>
          client.post<void>(
            `/locations/${locationId}/billing/payment-methods`,
            {
              token,
            }
          )
        )
        .then((r) => r.data)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['location-payment-methods', { locationId }],
      })
    },
  })
}
