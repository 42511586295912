import PageLayout from 'src/components/WafLayout/PageLayout'

const SamplePage: React.FC = () => {
  const baseDataAttribute = 'sample-page'

  return (
    <PageLayout title="Admin Sample Page" baseDataAttribute={baseDataAttribute}>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec
    </PageLayout>
  )
}

export default SamplePage
