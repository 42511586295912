import {
  createColumnHelper,
  flexRender,
  functionalUpdate,
  getCoreRowModel,
  getSortedRowModel,
  PaginationState,
  Updater,
  useReactTable,
} from '@tanstack/react-table'
import { useMemo } from 'react'
import { toast } from 'react-toastify'
import styled, { useTheme } from 'styled-components'

import {
  BlockAllowListItem,
  DEFAULT_TAKE,
  SpamAction,
  useBlockOrAllowPhoneNumber,
  useGetLocationBlockAllowList,
} from 'src/api'
import AddContactModal from 'src/containers/Contacts/AddContactModal'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import { useTableSearchParams } from 'src/hooks/useTableSearchParams'
import { Button } from 'src/stories/Button'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import { MoreNoFillIcon } from 'src/stories/assets'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { Table, TBody, Td, TFoot, Th, THead, Tr } from 'src/stories/table'
import { TablePagination } from 'src/stories/table/TablePagination'
import { Heading } from 'src/stories/typography'
import { formatPhoneNumber } from 'src/utils'

interface Props {
  action: SpamAction
  title: string
}

const ContentContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(3),
  marginBottom: theme.space(8),
}))

const StyledZeroStateTextContainer = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.space(5),
}))

const TableContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}))

const ToolbarContainer = styled.div(({ theme }) => ({
  display: 'grid',
  padding: theme.space(3),
  gap: theme.space(5),
  background: theme.colors.base_5,
  borderTopLeftRadius: theme.constants.borderRadius,
  borderTopRightRadius: theme.constants.borderRadius,
  borderInline: `1px solid ${theme.colors.base_20}`,
  borderTop: `1px solid ${theme.colors.base_20}`,
}))

const ToolbarOptions = styled.div<{ isDesktop: boolean }>(
  ({ theme, isDesktop }) => ({
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: isDesktop ? 'repeat(5, 1fr)' : '1fr',
    gap: theme.space(5),
  })
)

const getSuccessMessage = (phoneNumber: string, actionButton: string) => {
  return `Phone number ${formatPhoneNumber(
    phoneNumber
  )} ${actionButton.toLocaleLowerCase()}ed succesfully.`
}

const BlockOrAllowTable: React.FC<Props> = ({ action, title }) => {
  const baseDataAttribute = 'block-or-allow-table-header'
  const errorMessage =
    'There was an error trying to block/allow a phone number.'
  const theme = useTheme()
  const prefix = `${action.toLowerCase()}_`
  const {
    pagination,
    sort: sortQueryParam,
    locationId,
    setSearchParams,
  } = useTableSearchParams(DEFAULT_TAKE, prefix)
  const { data, isLoading, isFetching } = useGetLocationBlockAllowList({
    locationId,
    action,
    pagination,
  })

  const { mutateAsync: onBlockOrAllowPhoneNumber, isPending } =
    useBlockOrAllowPhoneNumber(locationId)
  const { showModal, closeModal } = useModalNotificationsContext()
  const { isMediumScreen: isDesktop } = useScreenSizes()
  const tablePagination = useMemo<PaginationState>(
    () => ({
      pageIndex: pagination.skip / pagination.take,
      pageSize: pagination.take,
    }),
    [pagination.skip, pagination.take]
  )
  const handlePagination = (paginate: Updater<PaginationState>) => {
    const { pageIndex: _pageIndex, pageSize: _pageSize } = functionalUpdate(
      paginate,
      tablePagination
    )

    setSearchParams((current) => {
      current.set(`${prefix}take`, _pageSize.toString())
      current.set(`${prefix}skip`, (_pageSize * _pageIndex).toString())

      return current
    })
  }

  const handleBlockOrAllow = async (
    phoneNumber: string,
    actionButton: string
  ) => {
    try {
      await onBlockOrAllowPhoneNumber({
        action: actionButton,
        phoneNumber,
      })
      const sucessMessage = getSuccessMessage(phoneNumber, actionButton)

      toast.success(sucessMessage)
    } catch (error) {
      toast.error(errorMessage)
    }
  }

  const columnHelper = createColumnHelper<BlockAllowListItem>()
  const columns = [
    columnHelper.accessor('phoneNumber', {
      header: 'Phone Number',
      cell: (info) => formatPhoneNumber(info.getValue()),
    }),
    columnHelper.accessor('action', {
      header: 'Status',
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      id: 'button',
      cell: (props) => {
        const rowData = props.row.original
        const value = rowData['phoneNumber']

        return (
          <Button
            label={action === SpamAction.ALLOW ? 'Block' : 'Unblock'}
            outline
            style={{ maxWidth: theme.space(25) }}
            onClick={() =>
              handleBlockOrAllow(
                value,
                action === SpamAction.ALLOW
                  ? SpamAction.BLOCK
                  : SpamAction.ALLOW
              )
            }
          />
        )
      },
    }),
  ]
  const table = useReactTable({
    columns,
    data: data?.pages[0]?.data || [],
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    manualSorting: true,
    onPaginationChange: handlePagination,
    pageCount: Math.ceil((data?.pages[0]?.total || 0) / (pagination.take || 1)),
    state: {
      pagination: tablePagination,
      sorting: sortQueryParam
        ? [
            {
              id: sortQueryParam.field ?? '',
              desc: sortQueryParam.direction === 'DESC',
            },
          ]
        : [],
    },
  })

  return (
    <ContentContainer>
      <Heading>{title}</Heading>
      <TableContainer>
        <ToolbarContainer data-cy={baseDataAttribute}>
          <ToolbarOptions isDesktop={isDesktop}>
            <Button
              label={isDesktop ? 'Add New' : 'Add'}
              icon={MoreNoFillIcon}
              onClick={() =>
                showModal({
                  title: 'Add contact',
                  dataCy: 'contacts-add-contact',
                  modalActionsOptions: {
                    callToAction: { label: 'Add contact' },
                  },
                  customBody: (
                    <AddContactModal
                      onComplete={async (_, contactPhoneNumber) => {
                        closeModal()
                        if (!contactPhoneNumber) return
                        try {
                          await onBlockOrAllowPhoneNumber({
                            action,
                            phoneNumber: contactPhoneNumber,
                          })
                          const sucessMessage = getSuccessMessage(
                            contactPhoneNumber,
                            action
                          )

                          toast.success(sucessMessage)
                        } catch (error) {
                          toast.error(errorMessage)
                        }
                      }}
                      dataCy="contacts-add-contact"
                      locationId={locationId}
                    />
                  ),
                })
              }
              baseDataAttribute={`${baseDataAttribute}-add-contact`}
              style={{ gridColumn: isDesktop ? '5 / 6' : '1 / 2' }}
            />
          </ToolbarOptions>
        </ToolbarContainer>
        <Table>
          <THead>
            {table.getHeaderGroups().map((group) => (
              <Tr backgroundColor="base_10" key={group.id}>
                {group.headers.map((header) => (
                  <Th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </Th>
                ))}
              </Tr>
            ))}
          </THead>
          <TBody>
            {isLoading || isFetching || isPending ? (
              <Tr>
                <Td colSpan={columns.length}>
                  <LoadingSpinner />
                </Td>
              </Tr>
            ) : table.getRowModel().rows.length ? (
              table.getRowModel().rows.map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  ))}
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={columns.length}>
                  <StyledZeroStateTextContainer>
                    No results
                  </StyledZeroStateTextContainer>
                </Td>
              </Tr>
            )}
          </TBody>
          <TFoot>
            <Tr>
              <Td colSpan={columns.length - 1} />
              <Td>
                <TablePagination table={table} />
              </Td>
            </Tr>
          </TFoot>
        </Table>
      </TableContainer>
    </ContentContainer>
  )
}

export default BlockOrAllowTable
