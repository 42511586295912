import React, { createContext, ReactElement, useMemo } from 'react'
import { useLocation } from 'react-router'
import styled, { CSSObject, useTheme } from 'styled-components'

// eslint-disable-next-line import/no-restricted-paths
import { Button } from './Button'
import Sidebar, { SidebarRoutes } from './Sidebar'
// eslint-disable-next-line import/no-restricted-paths
import useMhContext from 'src/contexts/MhContext'
import { CloseIcon as TimesIcon } from 'src/stories/assets'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'

export type ValidColW = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
interface ColProps {
  w?: ValidColW
  unspaced?: boolean
}
export const Col = styled.div<ColProps>(({ unspaced, w }) => ({
  margin: `0 ${unspaced ? 0 : '4px'}`,
  flexGrow: w || 1,
  flexShrink: w || 1,
  flexBasis: w || 0,
}))

interface StyledRowProps {
  $direction: 'row' | 'column' | 'row-reverse' | 'column-reverse'
  $height?: string
  $flexGrow?: number
  $minHeight?: string
}
export const StyledRow = styled.div<StyledRowProps>(
  ({ $direction, $flexGrow, $height, $minHeight, theme }) => ({
    margin: theme.space(0),
    display: 'flex',
    flexDirection: $direction,
    flex: 1,
    flexGrow: $flexGrow ?? 1,
    justifyContent: 'space-between',
    height: $height,
    minHeight: $minHeight,
  })
)

interface RowProps {
  height?: string
  minHeight?: string
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
  flexGrow?: number
}
export const Row: React.FCWithChildren<RowProps> = ({
  height,
  direction,
  flexGrow,
  minHeight,
  children,
  ...props
}) => {
  return (
    <StyledRow
      $direction={direction || 'row'}
      $height={height}
      $minHeight={minHeight}
      $flexGrow={flexGrow}
      {...props}
    >
      {children}
    </StyledRow>
  )
}

interface StyledBodyProps {
  $height?: string
  $invertedBackground?: boolean
  $allowScrolling?: boolean
}

interface StyledContentContainerProps {
  $fullWidth: boolean
  $removePadding?: boolean
  $expandView?: boolean
  $height: string
  $isMediumDesktop?: boolean
  $showDropShadow?: boolean
}
const StyledContentContainer = styled.div<StyledContentContainerProps>(
  ({ theme, $removePadding, $height, $isMediumDesktop }) => ({
    height: $height,
    padding: $removePadding
      ? '0'
      : $isMediumDesktop
      ? `${theme.space(3)} ${theme.space(7)} 0 ${theme.space(7)}`
      : `${theme.space(3)} ${theme.space(3)} 0 ${theme.space(3)}`,
  })
)

export interface SidebarContextProps {
  locationHash: string
  locationPath: string
}

const SidebarContext = createContext<SidebarContextProps>({
  locationHash: '',
  locationPath: '',
})

const StyledHeading = styled.div<{
  $height: string
  $isMediumDesktop?: boolean
}>(({ $height, $isMediumDesktop, theme }) => {
  const boxShadowHorizontalOffset = '0px'
  const boxShadowVerticalOffset = '4px'
  const boxShadowBlurRadius = '4px'
  const boxShadowSpreadRadius = '0px'
  const boxShadowColor = theme.colors.base_20

  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    height: $height,
    backgroundColor: theme.colors.base_0,
    boxShadow: [
      boxShadowHorizontalOffset,
      boxShadowVerticalOffset,
      boxShadowBlurRadius,
      boxShadowSpreadRadius,
      boxShadowColor,
    ].join(' '),
    width: '100%',
    padding: `0 ${$isMediumDesktop ? theme.space(7) : theme.space(5)}`,
    zIndex: theme.zIndexes.layout,
  }
})

const StyledBodyGrid = styled.main<StyledBodyProps & { $columns: number }>(
  ({ $columns, $invertedBackground, theme }) => ({
    display: 'grid',
    gridTemplateColumns: `repeat(${$columns}, 1fr)`,
    backgroundColor: $invertedBackground
      ? theme.colors.base_0
      : theme.colors.base_5,
  })
)

const StyledGridSidebarContainer = styled.section<{
  $rows: number
  $height: string
}>(({ $rows, $height, theme }) => ({
  gridColumnStart: 1,
  gridColumnEnd: 2,
  gridRowStart: 1,
  gridRowEnd: $rows + 1,

  width: theme.widths.sidebar,
  minWidth: theme.widths.sidebar,
  borderRight: `1px solid ${theme.colors.base_20}`,
  paddingTop: theme.space(7),
  backgroundColor: theme.colors.base_3,
  alignItems: 'center',
  overflowX: 'hidden',
  overflowY: 'auto',
  height: $height,
}))

const StyledGridTitleContainer = styled.section<{
  $columnStart: number
  $columnEnd: number
  $height: string
  $showDropShadow?: boolean
}>(({ $columnStart, $columnEnd, $height, $showDropShadow, theme }) => {
  return {
    gridColumnStart: $columnStart,
    gridColumnEnd: $columnEnd,
    gridRowStart: 1,

    height: $height,
    backgroundColor: theme.colors.base_0,
    width: '100%',
    zIndex: theme.zIndexes.layout + 1,
    boxShadow: $showDropShadow
      ? `0 ${theme.space(1)} ${theme.space(1)} 0 ${theme.colors.base_20}`
      : 'none',
  }
})

const StyledGridContentContainer = styled.section<{
  $columnStart: number
  $columnEnd: number
  $rows: number
  $height: string
  $removePadding?: boolean
  $fullWidth: boolean
}>(
  ({
    $columnStart,
    $columnEnd,
    $rows,
    $height,
    $removePadding,
    $fullWidth,
    theme,
  }) => ({
    gridColumnStart: $columnStart,
    gridColumnEnd: $columnEnd,
    gridRowStart: $rows,

    overflowX: 'hidden',
    overflowY: 'auto',
    transition: 'margin-right 0.3s',
    minHeight: $height,
    maxHeight: $height,
    height: $height,
    padding:
      $removePadding || $fullWidth
        ? theme.space(0)
        : `${theme.space(0)} ${theme.space(7)}`,
  })
)

const StyledGridSidedrawerContainer = styled.section<{
  $columnStart: number
  $columnEnd: number
  $rowEnd: number
  $open?: boolean
  $height?: string
}>(({ $columnStart, $columnEnd, $rowEnd, $open, $height, theme }) => {
  const styles: CSSObject = {
    gridColumnStart: $columnStart,
    gridColumnEnd: $columnEnd,
    gridRowStart: 2,
    gridRowEnd: $rowEnd,

    zIndex: theme.zIndexes.layout,
    overflowX: 'hidden',
    transform: $open ? 'translateX(0)' : 'translateX(100%)',
    transition: 'transform 0.3s ease-out',
    background: theme.colors.base_0,
    width: $open ? '100%' : '0',
    borderLeft: $open ? `1px solid ${theme.colors.base_20}` : '',
  }

  if ($height) {
    styles.minHeight = $height
    styles.maxHeight = $height
    styles.height = $height
  }

  return styles
})

const StyledGridSidedrawerHeading = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.colors.base_20}`,
  height: theme.space(16),
  padding: theme.space(6),
}))

const StyledGridSidedrawerTitle = styled.p(({ theme }) => ({
  fontSize: '1.6rem',
  fontWeight: 500,
  color: theme.colors.base_100,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))

const StyledCloseXIcon = styled(TimesIcon)(({ theme }) => ({
  marginRight: theme.space(1),
  fill: theme.colors.base_20,
  cursor: 'pointer',
}))

const StyledLayoutTitle = styled.h1(({ theme }) => ({
  padding: `0 ${theme.space(7)}`,
}))

export interface LayoutProps {
  /**
   * Title shown at the top of the page, outside of the content box
   */
  layoutTitle?: string | ReactElement
  /**
   * Title shown at the top of the sidebar
   */
  sidebarTitle?: string
  /**
   * Routes of the sidebar. When present, layout will resize accordingly
   */
  sidebarRoutes?: SidebarRoutes[]
  /**
   * Styling options allow for extra configuration of the layout behavior.
   */
  styleOptions?: {
    /**
     * Removes padding from the body-content-container
     */
    removePadding?: boolean
    /**
     * When multi locations header is present, layout will resize accordingly
     */
    withMultiLocationHeader?: boolean
    /**
     * Inverts the default color of the background, for certain designs requirements
     */
    invertedBackground?: boolean
    /**
     * Enables scrolling in the outermost container of the layout
     */
    allowScrolling?: boolean
    /**
     * Show a drop shadow
     */
    showDropShadow?: boolean
  }
  children:
    | React.ReactNode
    | ((props: SidebarContextProps) => React.ReactElement)
  /**
   * Optional React Component to render a footer
   * ```
   <Layout footer={<Component />}>
   {children}
   </Layout>
   ```
   */
  footer?: ReactElement
  /**
   * Optional object to display a title bar with functionality to render a sidedrawer
   * to the right of the page.
   */
  heading?: {
    /**
     * Flag to only display the sidedrawer and not the title bar
     */
    onlySidedrawer?: boolean
    /**
     * Heading Title
     */
    titleSlot: string | ReactElement
    /**
     * Label of the Button that opens the sidedrawer
     */
    openSidedrawerButtonLabel: string
    /**
     * `onClick` handler of the Button that opens the sidedrawer
     */
    openSidedrawerButtonOnClick?: () => void
    /**
     * `onClick` handler of the Button that closes the sidedrawer
     */
    closeSidedrawerButtonOnClick?: () => void
    /**
     * The sidedrawer title
     */
    sidedrawerTitle: string
    /**
     * The sidedrawer content
     */
    sidedrawerContent: ReactElement
    /**
     * The sidedrawer button
     */
    isSidedrawerButtonDisabled?: boolean
  }
  isSidedrawerOpen?: boolean
}

const Layout: React.FC<LayoutProps> = ({
  layoutTitle,
  sidebarTitle,
  sidebarRoutes,
  styleOptions,
  children,
  footer,
  heading,
  isSidedrawerOpen,
}) => {
  const location = useLocation()
  const theme = useTheme()
  const { isLargeScreen: isDesktop } = useScreenSizes()

  const { isMediumDesktop, openConversationId } = useMhContext()

  const shouldSidedrawerBeShown = useMemo(
    () => heading && !!isSidedrawerOpen,
    [heading, isSidedrawerOpen]
  )

  const columns = 12
  let rows = 1
  const endColumn = columns + 1
  const sideDrawerColumnsSpan = 3
  const renderTitleSection = !!(
    layoutTitle ||
    (heading && !heading.onlySidedrawer)
  )
  const renderSidebar = !!(
    sidebarRoutes &&
    sidebarRoutes.length > 0 &&
    isDesktop
  )
  const contentStartColumn = renderSidebar ? 2 : 1

  if (renderTitleSection) {
    rows += 1
  }

  const titleHeightUnits = renderTitleSection ? 12 : 0
  const titleHeight = theme.space(titleHeightUnits)
  const availableHeight = `calc(100vh - ${theme.heights.getHeadersHeights({
    navbar: true,
    multiLocationHeader: styleOptions?.withMultiLocationHeader,
    additionalHeight: titleHeightUnits,
  })})`
  const availableContentHeight = `calc(100vh - ${theme.heights.getHeadersHeights(
    {
      navbar: true,
      multiLocationHeader: styleOptions?.withMultiLocationHeader,
      additionalHeight: titleHeightUnits,
    }
  )})`

  return (
    <SidebarContext.Provider
      value={{ locationHash: location.hash, locationPath: location.pathname }}
    >
      <>
        <StyledBodyGrid
          id="body"
          $columns={columns}
          $invertedBackground={styleOptions?.invertedBackground}
        >
          {renderSidebar && (
            <StyledGridSidebarContainer
              $rows={rows}
              $height={availableHeight}
              data-cy="sidebar-container"
            >
              <Sidebar routes={sidebarRoutes} title={sidebarTitle} />
            </StyledGridSidebarContainer>
          )}
          {renderTitleSection && (
            <StyledGridTitleContainer
              $columnEnd={endColumn}
              $columnStart={contentStartColumn}
              data-cy="title-heading-container"
              $height={titleHeight}
              $showDropShadow={styleOptions?.showDropShadow}
            >
              {heading && (
                <StyledHeading
                  $height={theme.space(titleHeightUnits)}
                  $isMediumDesktop={isMediumDesktop}
                >
                  {typeof heading.titleSlot === 'string' ? (
                    <h1 data-cy="title-heading-label">{heading.titleSlot}</h1>
                  ) : (
                    heading.titleSlot
                  )}

                  {!!openConversationId && (
                    <Button
                      baseDataAttribute="title-heading"
                      label={heading.openSidedrawerButtonLabel}
                      maxWidth={theme.space(30)}
                      onClick={() => {
                        heading.openSidedrawerButtonOnClick?.()
                      }}
                      disabled={heading.isSidedrawerButtonDisabled}
                    />
                  )}
                </StyledHeading>
              )}
              {layoutTitle &&
                !heading &&
                (typeof layoutTitle === 'string' ? (
                  <StyledLayoutTitle>{layoutTitle}</StyledLayoutTitle>
                ) : (
                  layoutTitle
                ))}
            </StyledGridTitleContainer>
          )}
          <StyledGridContentContainer
            $columnEnd={
              endColumn - (shouldSidedrawerBeShown ? sideDrawerColumnsSpan : 0)
            }
            $rows={rows}
            $columnStart={contentStartColumn}
            $height={availableHeight}
            $fullWidth={
              !sidebarRoutes || sidebarRoutes.length === 0 || !isDesktop
            }
            $removePadding={styleOptions?.removePadding}
            data-cy="content-container"
          >
            <StyledContentContainer
              data-cy="content"
              $fullWidth={
                !sidebarRoutes || sidebarRoutes.length === 0 || !isDesktop
              }
              $removePadding={styleOptions?.removePadding}
              $height={availableContentHeight}
            >
              {typeof children === 'function' ? (
                <SidebarContext.Consumer>
                  {(context) => children(context)}
                </SidebarContext.Consumer>
              ) : (
                children
              )}
              {footer && <>{footer}</>}
            </StyledContentContainer>
          </StyledGridContentContainer>
          {
            <StyledGridSidedrawerContainer
              $rowEnd={rows}
              $columnStart={
                isDesktop
                  ? endColumn - sideDrawerColumnsSpan
                  : // isConvoLookingSuperNarrow
                  //   ? 1
                  //   : endColumn - sideDrawerColumnsSpan
                  isMediumDesktop
                  ? endColumn - sideDrawerColumnsSpan
                  : // isConvoLookingSuperNarrow
                    //   ? 1
                    //   : endColumn - sideDrawerColumnsSpan
                    1
              }
              $columnEnd={endColumn}
              data-cy="sidedrawer-container"
              $open={shouldSidedrawerBeShown}
              $height={
                !styleOptions?.allowScrolling ? availableHeight : undefined
              }
            >
              <StyledGridSidedrawerHeading data-cy="mh-contact-details-heading-container">
                <StyledGridSidedrawerTitle data-cy="mh-contact-details-heading-title">
                  {heading?.sidedrawerTitle}
                </StyledGridSidedrawerTitle>
                <StyledCloseXIcon
                  data-cy="mh-contact-details-heading-close"
                  onClick={() => {
                    heading?.closeSidedrawerButtonOnClick?.()
                  }}
                />
              </StyledGridSidedrawerHeading>
              {heading?.sidedrawerContent}
            </StyledGridSidedrawerContainer>
          }
        </StyledBodyGrid>
      </>
    </SidebarContext.Provider>
  )
}

export default Layout
