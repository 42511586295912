import React from 'react'

import InsightsContent from 'src/components/Insights/Content'
import {
  LRCallDetailsWidget,
  LRCallsByWeekdayWidget,
  LRCallsByWrapupCodeWidget,
} from 'src/components/Insights/Widgets'
import { InsightsContentContainer } from 'src/components/Insights/styled'
import WafLayout from 'src/components/WafLayout'
import useInsightsContext from 'src/contexts/InsightsContext'

interface InsightsProps {
  isMultiLocation: boolean
}

const Insights: React.FC<InsightsProps> = ({ isMultiLocation }) => {
  const { shouldShowLiveReceptionist, shouldShowAi } = useInsightsContext()

  return (
    <WafLayout
      layoutTitle="Insights"
      styleOptions={{
        withMultiLocationHeader: isMultiLocation,
        removePadding: false,
        showDropShadow: true,
      }}
      displayFooter
    >
      <InsightsContentContainer>
        {shouldShowLiveReceptionist && (
          <InsightsContent
            title="Live Receptionist Details (Last 30 Days)"
            content={{
              elements: [
                <LRCallDetailsWidget baseDataAttribute="insights-live-receptionist-call-details" />,
                <LRCallsByWeekdayWidget baseDataAttribute="insights-live-receptionist-calls-by-weekday" />,
                <LRCallsByWrapupCodeWidget baseDataAttribute="insights-live-receptionist-calls-by-wrapup-code" />,
              ],
            }}
          />
        )}
        {shouldShowAi && (
          <InsightsContent
            title="AI Receptionist Details (Last 30 Days)"
            content={{
              elements: [
                <LRCallDetailsWidget baseDataAttribute="insights-ai-call-details" />,
                <LRCallsByWeekdayWidget baseDataAttribute="insights-ai-calls-by-weekday" />,
              ],
            }}
          />
        )}

        {/* <InsightsContent
          title="Inbound Leads"
          content={{
            elements: [
              <TotalInstantResponsesSentWidget baseDataAttribute="insights-inbound-leads-total-instant-response" />,
              <InboundLeadsPerMonthWidget baseDataAttribute="insights-inbound-leads-per-month" />,
              <TotalContactsAddedFromLeadSourcesWidget baseDataAttribute="insights-inbound-leads-total-contacts-added-from-lead-sources" />,
            ],
          }}
        /> */}
      </InsightsContentContainer>
    </WafLayout>
  )
}

export default Insights
