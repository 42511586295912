import styled from 'styled-components'

import { Row } from 'src/stories/Layout'
import {
  HangUpIcon,
  MakeACallIcon,
  MuteIcon,
  OnACallIcon,
} from 'src/stories/assets'

export const Container = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(5),
  width: '100%',
}))

export const CallControlsContainer = styled.div<{ $isMediumScreen: boolean }>(
  ({ theme, $isMediumScreen: $isDesktop }) => ({
    display: 'flex',
    flexDirection: $isDesktop ? 'row' : 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.space($isDesktop ? 6 : 4),
    backgroundColor: theme.colors.base_10,
    padding: `${theme.space(4)} ${theme.space(5)}`,
    borderRadius: theme.constants.largeBorderRadius,
    width: '100%',
  })
)

export const CallContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.space(4),
  minHeight: theme.space(50),
  paddingBottom: theme.space(3),
}))

export const PhoneCallButton = styled(OnACallIcon)<{ $pending?: boolean }>(
  ({ theme, $pending = true }) => ({
    fill: !$pending ? theme.colors.primary_2 : theme.colors.base_0,
    color: !$pending ? theme.colors.base_0 : theme.colors.primary_2,
    stroke: !$pending ? theme.colors.base_0 : theme.colors.primary_2,
  })
)

export const HangUpButton = styled(HangUpIcon)(({ theme }) => ({
  cursor: 'pointer',
  fill: theme.colors.accent_2,
  stroke: theme.colors.base_0,
  color: theme.colors.base_0,

  ':hover': {
    fill: theme.colors.accent_2_70,
  },
}))

export const MakeACallButton = styled(MakeACallIcon)<{ $isDesktop: boolean }>(
  ({ theme, $isDesktop }) => ({
    cursor: 'pointer',
    fill: theme.colors.accent_3,
    stroke: theme.colors.base_0,
    color: theme.colors.base_0,
    height: theme.space(12),
    width: theme.space(12),
    order: $isDesktop ? 0 : 1,
    ':hover': {
      fill: theme.colors.accent_3_70,
    },
  })
)

export const MuteButton = styled(MuteIcon)<{ $active?: boolean }>(
  ({ theme, $active = false }) => ({
    cursor: 'pointer',
    fill: $active ? theme.colors.accent_2 : theme.colors.base_30,
    color: theme.colors.base_0,

    ':hover': {
      fill: $active ? theme.colors.accent_2_70 : theme.colors.base_20,
    },
  })
)

export const LastMessageContainer = styled.div(({ theme }) => ({
  padding: theme.space(3),
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.constants.largeBorderRadius,
  border: `1px solid ${theme.colors.base_20}`,
  backgroundColor: theme.colors.base_3,
  gap: theme.space(3),
  width: '100%',
}))

export const PhoneCallNotesContainer = styled.div(({ theme }) => ({
  padding: theme.space(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(3),
  width: '100%',
  border: `2px solid ${theme.colors.primary_2}`,
  borderRadius: theme.constants.largeBorderRadius,
}))

export const PhoneCallNotesTextBox = styled.textarea(({ theme }) => ({
  backgroundColor: theme.colors.base_0,
  width: '100%',
  marginBottom: theme.space(2),
  cursor: 'text',
  overflow: 'auto',
  border: 'none',
  resize: 'none',
  '&:focus': {
    outline: 'none',
  },
}))

export const PhoneCallNotesFooter = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  height: theme.space(8),
}))

export const PhoneCallNotesButtonSection = styled(Row)(({ theme }) => ({
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.space(2),
  height: theme.space(10),
}))

export const LastMessageHeader = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingBottom: theme.space(3),
  borderBottom: `1px solid ${theme.colors.base_20}`,
}))

export const LastMessageBodyContainer = styled.div(({ theme }) => ({
  minHeight: theme.space(20),
}))
