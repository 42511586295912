import { useCallback, useRef } from 'react'
import styled, { useTheme } from 'styled-components'

import CallsAction from './CallsAction'
import MakeACallModal from './Modals/MakeACallModal'
import useAuthContext from 'src/contexts/AuthContext'
import { useLocationContext } from 'src/contexts/LocationContext'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import Dropdown, { StyledNavigationItem } from 'src/stories/Dropdown'
import { ChevronIcon } from 'src/stories/Icons/ChevronIcon'
import { AccountIcon, QuestionIcon } from 'src/stories/assets'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import {
  formatPhoneNumber,
  generateLocationUrl,
  isInternalUser,
} from 'src/utils'

const StyledAccountIcon = styled(AccountIcon)(({ theme }) => ({
  cursor: 'pointer',
  fill: theme.colors.base_0,
}))

const ChevronContainer = styled.span({
  cursor: 'pointer',
})

const StyledChevronIcon = styled(ChevronIcon)(({ theme }) => ({
  cursor: 'pointer',
  fill: theme.colors.base_0,
}))

const StyledQuestionIcon = styled(QuestionIcon)(({ theme }) => ({
  display: 'block',
  cursor: 'pointer',
}))

const AccountContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.space(2),
}))

const StyledDropdownInnerContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: `${theme.space(2)} 0`,
}))

const Container = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.space(5),
  alignItems: 'center',
}))

const NavbarActions = () => {
  const { isSmallScreen, isExtraLargeScreen } = useScreenSizes()
  const chevronRef = useRef<HTMLSpanElement>(null)
  const theme = useTheme()
  const { showModal, closeModal } = useModalNotificationsContext()
  const { activeLocation } = useLocationContext()
  const { user } = useAuthContext()
  const internalUser = isInternalUser(user)

  const forwardingPhone = activeLocation.primaryRentedPhoneNumber

  const openMakeACallModal = useCallback(() => {
    if (forwardingPhone) {
      showModal({
        title: `Call from your Signpost number ${formatPhoneNumber(
          forwardingPhone
        )}`,
        dataCy: 'navbar-make-a-call-modal',
        hideActionButtons: true,
        customBody: <MakeACallModal closeCurrentModal={closeModal} />,
      })
    }
  }, [forwardingPhone, showModal, closeModal])

  return (
    <Container key="navbar-actions">
      {(isSmallScreen || isExtraLargeScreen) && (
        <CallsAction makeACallHandler={openMakeACallModal} />
      )}
      <a
        href="https://help.signpost.com/"
        data-cy="navbar-item-support-icon"
        target="_blank"
        rel="noreferrer"
      >
        <StyledQuestionIcon fill={theme.colors.base_0} />
      </a>
      <AccountContainer>
        <StyledAccountIcon
          data-cy="navbar-item-account-icon"
          onClick={() => {
            chevronRef.current?.click()
          }}
        />
        <ChevronContainer ref={chevronRef}>
          <StyledChevronIcon direction="down" />
        </ChevronContainer>
        <Dropdown anchor={chevronRef}>
          <StyledDropdownInnerContainer data-cy="navbar-item-arrow-down-icon-dropdown-inner-container">
            {internalUser && (
              <StyledNavigationItem
                to={generateLocationUrl(
                  activeLocation.id,
                  '/admin/sample-page'
                )}
                data-cy="navbar-item-arrow-down-icon-inner-dropdown-item-1"
              >
                Manage Customer Account
              </StyledNavigationItem>
            )}
            <StyledNavigationItem
              to="/logout"
              data-cy="navbar-item-arrow-down-icon-inner-dropdown-item-2"
            >
              Logout
            </StyledNavigationItem>
          </StyledDropdownInnerContainer>
        </Dropdown>
      </AccountContainer>
    </Container>
  )
}

export default NavbarActions
