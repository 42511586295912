import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

export interface UpdatePaymentInformationParams {
  token: string
  paymentMethodId?: string
}

export const useUpdatePaymentInformation = (locationId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({
      token,
      paymentMethodId,
    }: UpdatePaymentInformationParams) => {
      return getClient()
        .then((client) =>
          client.put<void>(`/locations/${locationId}/billing/payment-methods`, {
            token,
            paymentMethodId,
          })
        )
        .then((r) => r.data)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['location-payment-methods', { locationId }],
      })
    },
  })
}
