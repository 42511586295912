import React, { useState } from 'react'
import styled from 'styled-components'

import { Button } from 'src/stories/Button'
import Input from 'src/stories/Input'
import theme from 'src/stories/theme'

interface Props {
  initialFirstName?: string
  initialLastName?: string
  isPending: boolean
  onSave: (firstName?: string, lastName?: string) => Promise<void>
}

export const CallContactContainer = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.space(2),
  backgroundColor: theme.colors.base_10,
  padding: `${theme.space(4)} ${theme.space(5)}`,
  borderRadius: theme.constants.largeBorderRadius,
  width: '100%',
})

const ButtonContainer = styled.div({
  marginLeft: 'auto',
})

const Col = styled.div({
  width: '100%',
  flexDirection: 'column',
  flexGrow: 1,
})

const PhoneCallContact: React.FC<Props> = ({
  initialFirstName,
  initialLastName,
  isPending,
  onSave,
}) => {
  const [firstName, setFirstName] = useState<string | undefined>(
    initialFirstName
  )
  const [lastName, setLastName] = useState<string | undefined>(initialLastName)

  const handleSaveContact = async () => {
    await onSave(firstName, lastName)
  }

  return (
    <CallContactContainer>
      <Col>
        <Input
          name="first-name"
          label="First name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <Input
          name="last-name"
          label="Last name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </Col>
      <ButtonContainer>
        <Button
          label="Save"
          onClick={handleSaveContact}
          {...{
            maxHeight: theme.space(8),
            maxWidth: theme.space(20),
          }}
          disabled={isPending}
        />
      </ButtonContainer>
    </CallContactContainer>
  )
}

export default PhoneCallContact
