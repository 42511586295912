import { Route, Routes } from 'react-router-dom'

import SamplePage from './SamplePage'
import WafLayout, { WafLayoutProps } from 'src/components/WafLayout'
import useAccountContext from 'src/contexts/AccountContext'
import useAuthContext from 'src/contexts/AuthContext'
import { useLocationContext } from 'src/contexts/LocationContext'
import { useSidebarRoutes } from 'src/routes/hooks'
import { isInternalUser } from 'src/utils'
import { BaseLegacyPageProps } from 'src/utils/interfaces'

type AdminProps = BaseLegacyPageProps

const Admin: React.FC<AdminProps> = () => {
  const { locationId } = useLocationContext()
  const { locations } = useAccountContext()
  const { adminSidebarRoutes } = useSidebarRoutes(locationId, locations)
  const { user } = useAuthContext()
  const wafLayoutProps: Omit<WafLayoutProps, 'children'> = {
    sidebarTitle: 'Admin',
    sidebarRoutes: adminSidebarRoutes,
    styleOptions: {
      removePadding: false,
      withMultiLocationHeader: true,
      invertedBackground: true,
    },
  }

  return (
    <Routes>
      {isInternalUser(user) && (
        <Route
          path="sample-page"
          element={
            <WafLayout {...wafLayoutProps}>
              <SamplePage />
            </WafLayout>
          }
        />
      )}
    </Routes>
  )
}

export default Admin
