import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import SharedIntegrationSettings from './SharedIntegrationSettings'
import {
  IntegrationMappingType,
  useExtendFacebookToken,
  useGetIntegrationStatus,
} from 'src/api'
import { useDeleteFacebookIntegrationMutation } from 'src/api/hooks/mutations/useDeleteFacebookIntegrationMutation'
import { useLocationContext } from 'src/contexts/LocationContext'
import { Button } from 'src/stories/Button'
import { FacebookIcon } from 'src/stories/assets'
import {
  fbLogin,
  fbLogout,
  getFacebookLoginStatus,
  initFacebookSdk,
} from 'src/utils/facebook-sdk'
import logger from 'src/utils/logger'

const ButtonContainer = styled.div(({ theme }) => ({
  maxWidth: theme.space(40),
}))

const FacebookIntegrationSettings: React.FC = () => {
  const { locationId } = useLocationContext()
  const [isConnecting, setIsConnecting] = useState(false)
  const { data: facebookIntegration } = useGetIntegrationStatus({
    locationId,
    type: IntegrationMappingType.FACEBOOK,
  })
  const {
    mutateAsync: extendFacebookToken,
    isPending: isExtendingFacebookToken,
  } = useExtendFacebookToken(locationId)
  const { mutateAsync: onDeleteFacebookIntegration } =
    useDeleteFacebookIntegrationMutation(locationId)

  useEffect(() => {
    const initFacebook = async () => {
      setIsConnecting(true)
      initFacebookSdk()
      await getFacebookLoginStatus()

      setIsConnecting(false)
    }

    void initFacebook()
  }, [])

  const login = async () => {
    try {
      setIsConnecting(true)

      const loginStatus = await getFacebookLoginStatus()

      if (loginStatus.status === 'connected') {
        return
      }

      const response = await fbLogin({
        scope: [
          'public_profile',
          'email',
          'pages_manage_metadata', // subscribe pages to webhooks (also publishing)
          'pages_manage_posts', // post one-time campaigns
          'pages_messaging', // messaging
          'pages_read_user_content', // capture reviews
          'pages_show_list', // subscribe pages to webhooks
          'pages_read_engagement', // read pages data
        ].join(','),
      })

      if (response.status === 'connected') {
        if (response.authResponse?.accessToken) {
          try {
            await extendFacebookToken({
              accessToken: response.authResponse.accessToken,
            })
          } catch (error) {}
        }
      }
    } catch (error: unknown) {
      logger.error('Error logging in with Facebook', {
        error,
      })
    } finally {
      setIsConnecting(false)
    }
  }

  const logout = async () => {
    try {
      await onDeleteFacebookIntegration()
      await fbLogout()
    } catch (error: unknown) {
      logger.error('Error logging out of Facebook', {
        error,
      })
    }
  }

  const isLoading = isConnecting || isExtendingFacebookToken
  const isConnected = facebookIntegration?.status === 'READY'

  return (
    <SharedIntegrationSettings
      icon={FacebookIcon}
      title="Facebook"
      status={facebookIntegration?.status}
      description="Sync contacts, content, engagement data, and more from your other apps and platforms."
    >
      <ButtonContainer>
        {!isConnected && (
          <Button
            action="primary"
            label="Connect"
            disabled={isLoading}
            onClick={login}
          />
        )}

        {isConnected && (
          <Button
            action="danger"
            label="Disconnect"
            displayAsText
            onClick={logout}
          />
        )}
      </ButtonContainer>
    </SharedIntegrationSettings>
  )
}

export default FacebookIntegrationSettings
