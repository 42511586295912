import { useEffect, useMemo } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import Header from 'src/components/Header'
import MultiLocationHeader from 'src/components/Header/MultiLocationHeader'
import Admin from 'src/containers/Admin'
// import Campaigns from 'src/containers/Campaigns'
// import LegacyPages from 'src/containers/LegacyPages'
import Contacts from 'src/containers/Contacts'
import UploadContacts from 'src/containers/Contacts/UploadContacts'
import Insights from 'src/containers/Insights'
// import Integrations from 'src/containers/Integrations'
import MessagingHub from 'src/containers/MessagingHub'
// import Reviews from 'src/containers/Reviews'
import Settings from 'src/containers/Settings'
import useAccountContext from 'src/contexts/AccountContext'
import useAuthContext from 'src/contexts/AuthContext'
import { InsightsContextProvider } from 'src/contexts/InsightsContext'
import { MhContextProvider } from 'src/contexts/MhContext'
import useNavigationInterceptorContext from 'src/contexts/NavigationInterceptor'
import { isInternalUser as isInternalUserFn } from 'src/utils'

export const locationSlug = {
  'messaging-hub': 'Messaging Hub',
  insights: 'Insights',
  contacts: 'Contacts',
  settings: 'Settings',
  integrations: 'Integrations',
}

const LocationRoutes: React.FC = () => {
  const { user } = useAuthContext()
  const { locations } = useAccountContext()
  const { pathname } = useLocation()
  const { setPageTitle } = useNavigationInterceptorContext()

  const currentLocationSlug = `${pathname.split('/')[4]}`

  useEffect(() => {
    const slug = locationSlug[currentLocationSlug as keyof typeof locationSlug]

    if (slug) {
      setPageTitle(`${slug} - Signpost`)
    } else {
      setPageTitle('Signpost')
    }

    return () => {
      setPageTitle('Signpost')
    }
  }, [currentLocationSlug, setPageTitle])

  const settingsRoutes = useMemo(() => ['/settings/*'], [])
  const adminRoutes = useMemo(() => ['/admin/*'], [])

  // const integrationRoutes = useMemo(() => ['/integrations/connect'], [])

  const isInternalUser = useMemo(() => isInternalUserFn(user), [user])

  const isMultiLocation = useMemo(
    () => (locations || []).length > 1 || isInternalUser,
    [locations, isInternalUser]
  )

  return (
    <>
      {isMultiLocation && (
        <MultiLocationHeader isInternalUser={isInternalUser} />
      )}
      <Header pageType="Location" />

      <Routes>
        <Route
          path="/insights"
          element={
            <InsightsContextProvider>
              <Insights isMultiLocation={isMultiLocation} />
            </InsightsContextProvider>
          }
        />

        <Route
          path={'/messaging-hub'}
          element={
            <MhContextProvider>
              <MessagingHub isMultiLocation={isMultiLocation} />
            </MhContextProvider>
          }
        />

        <Route
          path={'/contacts'}
          element={<Contacts isMultiLocation={isMultiLocation} />}
        />

        <Route
          path={'/contacts/upload'}
          element={<UploadContacts isMultiLocation={isMultiLocation} />}
        />

        {settingsRoutes.map((path) => (
          <Route
            key={path}
            path={path}
            element={<Settings isMultiLocation={isMultiLocation} />}
          />
        ))}

        {isInternalUser &&
          adminRoutes.map((path) => (
            <Route
              key={path}
              path={path}
              element={<Admin isMultiLocation={isMultiLocation} />}
            />
          ))}

        {/* {integrationRoutes.map((path) => (
          <Route
            key={path}
            path={path}
            element={<Integrations isMultiLocation={isMultiLocation} />}
          />
        ))} */}
        <Route path="*" element={<Navigate to="./messaging-hub" replace />} />
      </Routes>
    </>
  )
}

export default LocationRoutes
