import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { SpamAction } from 'src/api/types'

interface BlockOrAllowPhoneNumberParams {
  action: string
  phoneNumber: string
}

export const useBlockOrAllowPhoneNumber = (locationId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: BlockOrAllowPhoneNumberParams) => {
      const client = await getClient()

      await client.patch(
        `locations/${locationId}/phone-numbers/${encodeURI(
          params.phoneNumber
        )}/${params.action}`
      )
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [
          'location-block-or-allow-list',
          {
            locationId,
            action: SpamAction.BLOCK,
          },
        ],
      })
      await queryClient.invalidateQueries({
        queryKey: [
          'location-block-or-allow-list',
          {
            locationId,
            action: SpamAction.ALLOW,
          },
        ],
      })
    },
  })
}
