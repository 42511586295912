import { useCallback, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components/macro'

import { getConversationsContacts } from './utils'
import {
  useCreateContact,
  useCreateContactChannel,
  useCreateConversation,
  useGetContactConversationsFetch,
  useGetContacts,
  useGetConversations,
} from 'src/api'
import { Contact } from 'src/api/types'
import ModalContactRecord, {
  ModalNewContactRecord,
} from 'src/components/Contacts/ModalContactRecord'
import { useShowPhoneCallModal } from 'src/components/MessagingHub/PhoneCallModal/hooks'
import { useLocationContext } from 'src/contexts/LocationContext'
import { useDebouncerState } from 'src/hooks/useDebounce'
import Input from 'src/stories/Input'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import { SearchIcon } from 'src/stories/assets'
import { isPhoneNumberValid } from 'src/utils'
import logger from 'src/utils/logger'

const StyledModalContainer = styled.div(({ theme }) => ({
  width: '100%',
  height: theme.space(122),
}))

const Col = styled.div({
  width: '100%',
})

const ScrollableContactsContainer = styled.div(({ theme }) => ({
  overflowY: 'scroll',
  height: theme.space(96),

  '&::-webkit-scrollbar': {
    width: '0px',
  },
}))

const Label = styled.label(({ theme }) => ({
  fontSize: theme.typography.heading.small.fontSize,
  fontWeight: theme.typography.weights.bold,
  display: 'flex',
  marginBottom: theme.space(3),
}))

const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
  fill: theme.colors.base_0,
  stroke: theme.colors.base_40,
}))

interface MakeACallModalParams {
  closeCurrentModal: () => void
}

const MakeACallModal = ({ closeCurrentModal }: MakeACallModalParams) => {
  const { locationId } = useLocationContext()
  const showPhoneCallModal = useShowPhoneCallModal()
  const getContactConversations = useGetContactConversationsFetch(locationId)

  const { mutateAsync: createContact } = useCreateContact(true)
  const { mutateAsync: createConversation } = useCreateConversation()
  const { mutateAsync: createContactChannel } =
    useCreateContactChannel(locationId)

  const [contactInputValue, setContactInputValue] = useState('')

  const { data: conversationsData, isLoading: isConversationsLoading } =
    useGetConversations({
      locationId,
    })

  const {
    data: contactsData,
    refetch,
    isFetching: isContactsLoading,
  } = useGetContacts(
    {
      locationId,
      pagination: { skip: 0, take: 10 },
      search: contactInputValue,
    },
    false
  )

  const getContactConversationId = async (contactId: number) => {
    const conversations = await getContactConversations(contactId)
    let conversationId = conversations.at(0)?.id

    if (!conversations.length) {
      conversationId = await createConversation({
        contactId,
        locationId,
      })
    }

    return conversationId
  }

  const contactClickHandler = async (contact: Contact) => {
    const { id: contactId, phoneNumber, channels } = contact

    try {
      if (channels.length === 0) {
        await createContactChannel({
          contactId,
          phoneNumber,
          emailAddress: '',
        })
        logger.debug('New channel created', {
          locationId,
          contactId,
        })
      }

      const conversationId = await getContactConversationId(contactId)

      showPhoneCallModal(contactId, phoneNumber, conversationId)
      closeCurrentModal()
    } catch (error) {
      logger.error('Error calling selected contact (MakeACallModal)', {
        locationId,
        contactId,
        message: (error as Error).message,
      })

      toast.error('Something went wrong. Please try again.')
    }
  }

  const onNewContactCallHandler = async (phoneNumber: string) => {
    if (isPhoneNumberValid(phoneNumber)) {
      let contactId = -1
      let channelId: number | undefined = undefined
      let conversationId: number | undefined = undefined

      try {
        const contact = await createContact({
          firstName: phoneNumber,
          lastName: '',
          phoneNumber,
          locationId,
        })

        if (contact) {
          contactId = typeof contact === 'number' ? contact : contact.id
          channelId =
            typeof contact === 'number' ? undefined : contact.channels.at(0)?.id

          logger.debug('New Contact created.', {
            locationId,
            contactId,
            channelId,
            phoneNumber,
          })

          conversationId = await getContactConversationId(contactId)

          logger.debug('New Conversation created.', {
            locationId,
            contactId,
            conversationId,
          })

          showPhoneCallModal(contactId, phoneNumber, conversationId, true)
          closeCurrentModal()
        } else {
          logger.error(
            `New Contact not created. Unable to call '${phoneNumber}'`
          )
          throw new Error('New Contact not created (MakeACallModal)')
        }
      } catch (error) {
        logger.error(
          'Error creating objects to call new contact (MakeACallModal)',
          {
            locationId,
            phoneNumber,
            contactId,
            conversationId,
            message: (error as Error).message,
          }
        )

        toast.error('Something went wrong. Please try again.')
      }
    }
  }

  const [handleContactSearch, isDebouncing] = useDebouncerState<() => void>(
    useCallback(async () => await refetch(), [refetch]),
    200
  )

  const conversationsContacts = useMemo(
    () => getConversationsContacts(conversationsData),
    [conversationsData]
  )

  const contactsList = contactsData?.data || conversationsContacts

  const showLoadingSpinner =
    isConversationsLoading || isContactsLoading || isDebouncing

  const listLabel = !!contactsList.length
    ? 'Most Recent contacts:'
    : 'Call new contact:'

  return (
    <StyledModalContainer data-cy="make-a-call-modal-container">
      <Col>
        <Input
          name="contact-phone-input"
          data-cy="contact-phone-input"
          Icon={StyledSearchIcon}
          placeholder="Search for a contact or enter a phone number"
          value={contactInputValue}
          onChange={(e) => {
            setContactInputValue(e.target.value)
            handleContactSearch()
          }}
        />
      </Col>
      {showLoadingSpinner ? (
        <LoadingSpinner />
      ) : (
        <Col>
          <Label>{listLabel}</Label>
          <ScrollableContactsContainer>
            {!!contactsList.length ? (
              contactsList.map((contact, index) => (
                <ModalContactRecord
                  key={`call-contact-${index}`}
                  contact={contact}
                  contactClickHandler={contactClickHandler}
                />
              ))
            ) : (
              <ModalNewContactRecord
                phoneNumber={contactInputValue}
                newContactClickHandler={onNewContactCallHandler}
              />
            )}
          </ScrollableContactsContainer>
        </Col>
      )}
    </StyledModalContainer>
  )
}

export default MakeACallModal
