import React from 'react'
import { useTheme } from 'styled-components'

import {
  PhoneCallNotesButtonSection,
  PhoneCallNotesContainer,
  PhoneCallNotesFooter,
  PhoneCallNotesTextBox,
} from 'src/components/MessagingHub/PhoneCallModal/styled'
import { Button } from 'src/stories/Button'

interface PhoneCallNotesProps {
  notes: string
  isPending: boolean
  handleNotesChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  handleSaveNotes: () => void
}

const PhoneCallNotes: React.FC<PhoneCallNotesProps> = ({
  notes,
  isPending,
  handleNotesChange,
  handleSaveNotes,
}) => {
  const theme = useTheme()

  const phoneCallNotesButtonProps = {
    maxHeight: theme.space(8),
    maxWidth: theme.space(20),
  }

  return (
    <PhoneCallNotesContainer>
      <PhoneCallNotesTextBox
        placeholder="Enter your notes here"
        value={notes}
        onChange={handleNotesChange}
      />
      <PhoneCallNotesFooter>
        <PhoneCallNotesButtonSection direction="row">
          <Button
            label="Save"
            baseDataAttribute="phone-call-notes-save-button"
            disabled={notes.trim() === '' || isPending}
            {...phoneCallNotesButtonProps}
            onClick={handleSaveNotes}
          />
        </PhoneCallNotesButtonSection>
      </PhoneCallNotesFooter>
    </PhoneCallNotesContainer>
  )
}

export default PhoneCallNotes
