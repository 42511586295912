import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'

import {
  Location,
  useGetAccountLocations,
  useGetLocation,
  useGetUserLocations,
} from 'src/api'
import { LocationInterface, UserInterface } from 'src/client/interfaces/Common'
import NotFound from 'src/components/NotFound'
import useAuthContext from 'src/contexts/AuthContext'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import { isInternalUser } from 'src/utils'

interface AccountContextProps {
  /** @deprecated */
  legacyUser: UserInterface
  /** @deprecated */
  legacyLocations: LocationInterface[]
  handleRefetchUserLocations: () => Promise<void>
  locations: Location[]
  showPreonboardingPopup: boolean
}

export const AccountContext = React.createContext<AccountContextProps>(
  {} as AccountContextProps
)

export const AccountContextProvider: React.FCWithChildren = ({ children }) => {
  const { user, legacyUser } = useAuthContext()
  const internalUser = isInternalUser(user)
  const { pathname } = useLocation()
  const pageLocationId = (/^\/(\d+)\//.exec(pathname) || [])[1]

  const {
    data: locations,
    refetch: fetchLocations,
    isLoading: isLoadingLocations,
  } = useGetUserLocations(!internalUser)
  const {
    data: pageLocation,
    refetch: fetchPageLocation,
    isLoading: isLoadingPageLocation,
  } = useGetLocation({ locationId: +pageLocationId, enabled: internalUser })
  const {
    data: accountLocations,
    isLoading: isLoadingAccountLocations,
    refetch: fetchAccountLocations,
  } = useGetAccountLocations(
    (internalUser && pageLocation?.accountId) || undefined
  )

  const isLoading =
    isLoadingLocations || isLoadingPageLocation || isLoadingAccountLocations
  const userLocations = internalUser ? accountLocations : locations

  const handleRefetchUserLocations = async () => {
    if (internalUser) {
      await fetchPageLocation()
      await fetchAccountLocations()
    } else {
      await fetchLocations()
    }
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!internalUser && userLocations?.length === 0) return <NotFound />

  const contextValue: AccountContextProps = {
    legacyUser,
    legacyLocations: [],
    handleRefetchUserLocations,
    locations: userLocations ?? [],
    showPreonboardingPopup: !!pageLocation?.showInitialPopup,
  }

  return (
    <AccountContext.Provider value={contextValue}>
      {children}
    </AccountContext.Provider>
  )
}

const useAccountContext = () => useContext(AccountContext)

export default useAccountContext
