import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

interface CreateFeedbackParams {
  conversationId: number
  eventId: number
  feedback: string
}

export const useCreateFeedback = (locationId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: CreateFeedbackParams) => {
      const client = await getClient()

      await client.post(
        `/locations/${locationId}/conversations/${params.conversationId}/events/${params.eventId}/feedback`,
        {
          feedback: params.feedback,
        }
      )
    },
    onSuccess: async (_, params: CreateFeedbackParams) => {
      await queryClient.invalidateQueries({
        queryKey: [
          'conversation-events',
          {
            locationId,
            conversationId: params.conversationId,
          },
        ],
      })
    },
  })
}
