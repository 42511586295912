import { ErrorMessage } from '@hookform/error-message'
import React from 'react'
import styled from 'styled-components'

import {
  sharedInputTextAreaStyles,
  StyledHelpLabel,
  StyledInputContainer,
  StyledLabel,
  StyledValidationErrorText,
} from './commonStyles'

interface StyledInputProps {
  error?: boolean
  type?: string
  disabled?: boolean
  hasIcon?: boolean
}

const StyledInput = styled.input<StyledInputProps>(
  ({ error, theme, type, disabled, hasIcon = false }) => ({
    ...sharedInputTextAreaStyles({ error, theme, disabled }),
    width: type === 'checkbox' ? theme.space(6) : '100%',
    height: type === 'checkbox' ? theme.space(6) : theme.space(11),
    cursor: type === 'checkbox' ? 'pointer' : undefined,
    padding: `0 ${hasIcon ? theme.space(8) : theme.space(3)}`,
  })
)

const IconContainer = styled.span(({ theme }) => ({
  position: 'absolute',
  left: theme.space(2.5),
  top: '50%',
  transform: 'translateY(-50%)',
}))

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string | JSX.Element
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>
  placeholder?: string
  helpLabel?: string | JSX.Element
  type?: string
  errors?: Record<string, unknown>
  focusOnLoad?: boolean
  verticallySpaced?: boolean
  verticallySpace?: number
  containerStyle?: React.CSSProperties
  name: string
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      Icon,
      placeholder,
      helpLabel,
      type,
      focusOnLoad,
      errors,
      verticallySpaced = true,
      verticallySpace = 8,
      containerStyle,
      ...props
    },
    ref
  ) => {
    return (
      <StyledInputContainer
        verticallySpaced={verticallySpaced}
        verticallySpace={verticallySpace}
        style={containerStyle}
        type={type}
      >
        {label && <StyledLabel $type={type}>{label}</StyledLabel>}
        {helpLabel && <StyledHelpLabel>{helpLabel}</StyledHelpLabel>}
        <StyledInput
          ref={ref}
          type={type || 'text'}
          autoFocus={focusOnLoad}
          placeholder={placeholder}
          hasIcon={!!Icon}
          error={!!(errors && errors[props.name])}
          {...props}
        />
        {Icon && (
          <IconContainer data-cy="input-icon-container">
            <Icon />
          </IconContainer>
        )}
        {errors && (
          <ErrorMessage
            errors={errors}
            name={props.name}
            render={({ message }) => (
              <StyledValidationErrorText>{message}</StyledValidationErrorText>
            )}
          />
        )}
      </StyledInputContainer>
    )
  }
)

export default Input
